// Snackbar.tsx
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Snackbar = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export const showSuccessSnackbar = (message: string) => {
  // Dismiss any currently displayed toast
  toast.dismiss();
  
  // Show the success toast
  toast.success(message);
};

export const showErrorSnackbar = (message: string) => {
  // Dismiss any currently displayed toast
  toast.dismiss();

  // Show the error toast
  toast.error(message);
};

export default Snackbar;
