import React from 'react';
import {
  Box,
  Modal,
  Typography,
} from '@mui/material';
import {
  Container,
  InputTextField,
  StyledBox,
  StyledButton,
} from './Modal.styles';
import { IModalProps } from './types';

const GenericModal = (props: IModalProps) => {
  const {
    id, open, onClose, onSave, title, content, defaultTextData, addButtonLabel, textFieldLabel,
  } = props;
  const [data, setData] = React.useState(defaultTextData || '');
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!data.trim()) {
      return;
    }
    onSave(data);
    onClose();
  };
  const handleTextChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setData(e.target.value);
  };
  return (
    <Modal
      key={id}
      open={open}
      onClose={onClose}
    >
      <StyledBox>
        <Typography className="head, g" id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {content}
          <form onSubmit={handleSubmit}>
            <Container>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >
                <InputTextField
                  label={textFieldLabel}
                  variant="filled"
                  color="success"
                  value={data}
                  onChange={handleTextChange}
                />
              </Box>
            </Container>
            <StyledButton
              type="submit"
              variant="outlined"
            >
              {addButtonLabel}
            </StyledButton>
          </form>
        </Typography>
      </StyledBox>
    </Modal>
  );
};

GenericModal.defaultProps = {
  id: '',
  open: false,
  onClose: () => {},
  onSave: () => {},
  title: 'Modal Title',
  content: <p>Modal content</p>,
  defaultTextData: '',
  addButtonLabel: 'Add',
  textFieldLabel: 'Text',
} as Partial<IModalProps>;

export default GenericModal;
