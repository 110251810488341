export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
  inherit = 'inherit',
}

export interface ButtonProps {
  color?: ButtonColor;
  children?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'text' ;
  onClick?: (e: any) => void
  customClasses?: object,
}
