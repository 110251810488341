const apiRequests = {
  addToDoOptions: {
    url: 'http://localhost:7000/api/todo',
    method: 'POST',
  },
  getToDoListOptions: {
    url: 'http://localhost:7000/todo',
    method: 'GET',
  },
  updateToDoOptions: {
    url: 'todo',
    method: 'PUT',
  },
  deleteToDoOptions: {
    url: 'todo',
    method: 'DELETE',
  },
};

export default apiRequests;
