import React from 'react';
import ReactDOM from 'react-dom/client';
// import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import GlobalStyles from './GlobalStyles';
import App from './App';
import '@fontsource/roboto';
import { globalTheme as projectTheme } from './config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={projectTheme}>
    <GlobalStyles />
    {/* <Provider> */}
      <App />
    {/* </Provider> */}
  </ThemeProvider>,
);
