import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
        font: 62.5% / 1.15 sans-serif;
    }

    body {
        font: 1.6rem/1.25 Arial, sans-serif;
        background-color: #f5f5f5;
        color: #4d4d4d;
    }
    .MuiButton-containedPrimary {
        background: rgb(37, 89, 195);
        color: white;
    }
    .ck-editor {
        .ck-content {
            height: 195px;
            }
    }
    .cs_btn_type {
        color: white;
        border-radius: 5px;
        font-size: 14px;
        padding: 12px 20px;
        margin-top: 15px;
        background: #2559C3;
        text-transform:capitalize;
        line-height:1.2;
    }
    .d-flex{
        display:flex;
    }
    .align-items-center{
        align-items:center;
        justify-content: flex-end !important;
    }
    .justify-content-center{
        justify-content:center;
    }
    .justify-content-between{
        justify-content:space-between;
    }
    .justify-content-end{
        justify-content:flex-end !important;
    }
    .admin_addnew_modal,.csmdl_style{
        form{
            label{
                padding-left:0 !important;
                margin-bottom:10px;
            }
        }
        div.admin_addnew_modal_footer{
            &.bdset{
                padding-top:2rem;
                border-top:1px solid #ddd;
                margin-top:2rem;
            }
            button{
                border-radius:5px;
                &.MuiButton-containedPrimary{
                    color:white;
                    margin-left:1rem;
                    background: rgb(37, 89, 195);
                }
            }
        }
    }
    .csmdl_style{
        .MuiCardHeader-root{
            background:rgba(37, 89, 195, 0.1) !important;
        }
        form {
            fieldset{
                top:0;
            }
        }
        .MuiBox-root{
            justify-content:space-between;
            gap:0;
            >div{
                flex:0 0 calc(50% - 12px);
                width:auto;
                max-width:calc(50% - 12px);
                min-width:auto;
                >div{
                    width:100%;
                    max-width:100%;
                    min-width:100%;
                    .MuiFormControl-root{
                        max-width:100%;
                        flex:0 0 100%;
                        width:100%;
                    }
                }

                .css-2m9kme-MuiFormControl-root{
                    width:100%;
                    margin:0;
                }
                label{
                    margin:0 0 10px;
                    padding:0 !important;
                }
            }
        }
        legend{
            display:none;
        }
        .add_line_wrp{
            width:100%;
            >div+div{
                margin-left:12px;
            }
            .added_input_wrp{
                min-width: 80%;
                display: flex;
                gap: 15px;
               &>div {
                    flex: 0 0 calc(50% - 7px) !important;
                    max-width: calc(50% - 7px) !important;
                    min-width: auto !important;
                }
            }
            .action_btn_wrp{
                display: flex;
                flex:1;
                align-items: center;
                padding-top: 3rem;
                >button:first-child{
                    background:green;
                    color:white;
                }
                >button:last-child{
                    background:#ff0000;
                    margin-left:10px;
                    color:white;
                }
            }
        }
    }

    .mdl_success_style{
        width: 500px;
        padding: 40px;
        text-align: center;
        display: block;
        border: 2px dotted rgba(46, 125, 50, 0.58);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        .MuiAlert-icon{
            -webkit-box-pack: center;
            justify-content: center;
            color: rgb(0, 158, 35);
            width: 60px;
            height: 60px;
            margin: 0px auto;
            border: 4px solid currentColor;
            border-radius:50%;
            svg{
                display:none;
            }
            &:after{
                content: "";
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                width: 33px;
                height: 15px;
                border: 4px solid currentColor;
                border-width: 4px 4px 0 0;
                transform: rotate(-222deg);
                position: relative;
                top: 9px;
            }


            }

        }
        .MuiAlert-message{
            font-weight:500;
            font-size:18px;
            color:#009E23;
        }
        .MuiAlert-action{
            position:absolute;
            right:1.5rem;
            top:.5rem;
            svg{
                width:22px;
                height:22px;
            }
        }
        [aria-label="Close"]{
            width:24px;
            height:24px;
        }
        .btn_wrp{
            padding-top:1.5rem;
            button{
                background: #0989e3;
                border-color:#0989e3;
                border-radius:0;
                padding:10px 20px;
                line-height:1.2;
                color:white;
                border-radius:30px;
                &:hover{
                    background:#005db0;
                }
            }
        }
    }
    .sure_mdlbox{
        .MuiDialog-paper {
            padding:15px;
            .MuiButton-containedPrimary{
                color: white;
                border-radius: 4px;

            }
        }
    }

    .btn_style_prt {
        .MuiButton-containedPrimary{
            border-radius: 5px;
            font-size: 14px;
            padding: 12px 20px;
            margin-top: 15px;
            background: rgb(37, 89, 195);
            text-transform: capitalize;
            line-height: 1.2;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
        }
    }
    .pgntn_box{
        padding:5px 1.4rem 0 0;
        .showing_rslt{
            text-align:right;
            padding-right:1rem;
        }
        .cs_pgntn{
            padding-top:12px;
            .MuiPaginationItem-textPrimary[aria-current="true"]{
                color:white;
            }
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .rbac_content_header h5{
        font-size: 28px;
        font-weight: 700;
    }
    .text-capitalize{
        text-transform: capitalize !important;
    }
    @keyframes App-logo-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
    }
    body .title_icn{
        border-radius: 0 !important;
        display: inline-block;
        vertical-align: bottom;
        margin-right: 8px;
        width: 30px;
        height: 33px;
    }
    body .title_icn img{
        object-fit: contain;
    }
    [data-testid="EditIcon"]{
        background: url(../edit.svg) no-repeat center;
      }
      [data-testid="EditIcon"] *{
        display: none;
      }
      [data-testid="DeleteIcon"]{
        background: url(../delete.svg) no-repeat center ;
      }
      [data-testid="DeleteIcon"] *,[data-testid="InfoIcon"] *{
        display: none;
      }
    [data-testid="EditIcon"]+[data-testid="DeleteIcon"]+[data-testid="VisibilityIcon"]{
        margin-left: 10px;
    }
    body [data-testid="InfoIcon"]{
        background: url(../info.svg) no-repeat center ;
        width: 16px;
        height: 16px;
    }
    .d-none{
    display: none !important;
    }
    .pr-3{
        padding-right: 1rem;
    }
    .cs_pgntn .Mui-selected{
        background: rgb(37, 89, 195) !important;
        color:white !important;
    }
    .score_mapping{
        background: white;
        padding: 12px;
        border-radius: 5px;
        font-family: Lato;
        text-align: -webkit-center;
        overflow: auto;
        .MuiDataGrid-root{
            background: #EFF1F8;
            border-radius: 5px;
            width: fit-content;
        }

        .MuiDataGrid-columnHeaders,[data-colindex="0"]{
            background: rgba(37, 89, 195, 0.25);
            font-weight: 400;
            color: #2559C3;
            text-align: left;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .left-actions {
            background: rgb(239, 241, 248);
            .MuiDataGrid-iconButtonContainer {
                visibility: visible;
                .MuiDataGrid-sortIcon {
                    opacity: 1;
            }
           
            }
            .MuiDataGrid-menuIcon {
                visibility: visible;
                width: auto;
            }
        }
        [data-colindex="0"]{
            display: flex;
            white-space: normal;
            word-wrap: break-word;
            line-height: 1.2;
            font-weight: 700;
        }
        .MuiDataGrid-withBorderColor{
            border-bottom-style: none;
        }
        .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-withBorderColor{
            border-bottom-style: none;
            place-content: center;
        }
        .MuiDataGrid-columnHeader--emptyGroup[aria-colindex="1"]{
            background: #EFF1F8;
        }
        [aria-label=" "]{
            background: #EFF1F8;
        }
        .MuiDataGrid-columnHeaderTitle{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            white-space: break-spaces;
            line-height: 1.2;
            font-weight: 700;
        }

        .score-map-grid{
            .MuiDataGrid-cellContent{
                width: 100%;
            }
        }
    }
    .bu-comp-wrap{
        .list-list_table{
            padding : 8px 0px !important;
            marin-left: -11px;
          }
          .table-row-item > .list-list_table-box-wrap:first-child {
              padding-left:20px;
            }
            .list_table-table-head:first-child {
                margin-left: 11px !important;
            }
            marginBottom: 10px;
    }
`;
export default GlobalStyles;
