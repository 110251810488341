import styled from "styled-components"

const AddKRAFormWrapper = styled.div`

.add-kra-field label{
    font-size: 16px;
    margin: 10px 0;
}

.key-responsibility-label{
    font-weight: 400; 
    display:block; 
}

`
export { AddKRAFormWrapper}