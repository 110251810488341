import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { ToastProps } from './types';

const Toast = (props: ToastProps) => {
  const {
    customClasses = {},
    open,
    onClose,
    autoHideDuration,
    message,
    action,
    vertical,
    horizontal,
    children,
  } = props;

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      message={message}
      classes={{ ...customClasses }}
      action={action}
      anchorOrigin={{ vertical, horizontal }}
      {...props}
    >
      {children}
    </Snackbar>
  );
};

Toast.defaultProps = {
  open: true,
  message: 'Hello, This is a toast message',
  autoHideDuration: 2000,
  vertical: 'bottom',
  horizontal: 'left',
};

export default Toast;
