import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

// import LoaderWrapper from './LoaderWrapper';
import { LoaderProps } from './types';

const Loader = (props: LoaderProps) => {
  const {
    color,
    ...progressDefaultProps
  } = props;

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
    <Stack display="flex" justifyContent="center" alignItems="center">
      <CircularProgress {...progressDefaultProps} />
    </Stack>
    </div>
  );
};

export default Loader;
