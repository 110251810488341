import styled from 'styled-components';

const CkeditorStyled = styled.div`
.ck-content {
    min-height: 200px; 
    max-height: 250px; 
    overflow-y: auto;  
  }

  `;

export { CkeditorStyled };