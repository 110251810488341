// Editor.tsx

import React, { useState, useEffect } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  CkeditorStyled,
} from './Ckeditor.styled';
import { GlobalStyles } from '@mui/material';
interface EditorProps {
  content: string;
  styleSet?: React.CSSProperties;
  onChange: (value: string) => void;
}

const Editor = (props: EditorProps): JSX.Element => {
  const { content, styleSet, onChange } = props;
  const [editor, setEditor] = useState<ClassicEditor | null>(null);

  const inputHandler = (event: any, editor: any) => {
    const newContent = editor.getData();
    onChange(newContent); // Notify the parent component of changes
  };

  return (
    <CkeditorStyled>
      <GlobalStyles
        styles={{
          ".ck.ck-balloon-panel": {
            zIndex: "9999 !important",
          }
        }}
      />
      <div style={styleSet}>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          watchdogConfig={{ crashNumberLimit: 10 }}
          onReady={(editor: ClassicEditor) => {
            setEditor(editor);
          }}
          config={{
            removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed', 'Table']
          }}
          onChange={inputHandler}
        />
      </div>
    </CkeditorStyled>
  );
};

export default Editor;
