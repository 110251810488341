import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { ErrorBoundary, Loader } from './components';
import { theme } from './layouts';
import AppRouter from './routes/AppRouter';
import Snackbar from './utils/Snackbar';

const TodoList = lazy(() => import('./modules/TodoList/TodoList'));

const App = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <AppRouter />
            <Snackbar />
          </Suspense>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

export default App;
