import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Avatar,
  MenuItem,
  Menu,
  Modal,
  Button,
  ListItemIcon,
  Grid,
} from '@mui/material';
import {
  Search, NotificationsNone, KeyboardArrowDown, Person, Logout,
} from '@mui/icons-material';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, Loader } from '../components';
interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmLogout: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirmLogout,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="logout-confirmation-modal"
    aria-describedby="logout-confirmation-modal"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 296,
        bgcolor: 'background.paper',
        borderRadius: 3,
        p: 4,
      }}
    >
      <Typography variant="h6" id="logout-confirmation-modal" component="div" align="center" fontWeight="600" color="#000000">
        Are you sure you want to logout?
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'center', mt: 2, gap: '8px',
      }}
      >
        <Button
          variant="outlined"
          sx={{
            mr: 1, backgroundColor: 'white', textTransform: 'none', color: '#2559C3', borderColor: '#2559C3',
          }}
          onClick={onClose}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          sx={{
            mr: 1, backgroundColor: '#2559C3', textTransform: 'none', color: '#fff',
          }}
          onClick={onConfirmLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  </Modal>
);

const Header: React.FC = () => {
  // const user = useSelector((state: any) => state.user.employee);
  // const { firstName, profile } = user;
  const navigate = useNavigate();
  const fullName = `PMS ADMIN`;
  const profile = { department: 'Admin', designation: 'Software Engineer' };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hanldeChangePassowrd = () => {
      navigate("/chnage-password");  
  }

  const handleLogout = () => {
    handleClose();
    setShowConfirmation(true);
  };

  const handleConfirmLogout = () => {
    // signOut(auth);
    localStorage.clear();
    setShowConfirmation(false);
    navigate("/");  
  };

  const handleModalClose = () => {
    setShowConfirmation(false);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff',
        padding: '10px',
        boxShadow: 'none',
        borderBottom: '1px solid #EEEEEE',
      }}
    >
      <Grid item xs={6}>
        <Image src="image 5.png" />
      </Grid>
      <Grid container item xs={6} md={6}>
        <Grid container item xs={6} md={8} justifyContent="end">
          {/* <IconButton sx={{ color: '#2559C3', width: '32px' }}>
            <Search />
          </IconButton>
          <IconButton sx={{ color: '#2559C3' }}>
            <NotificationsNone />
          </IconButton> */}
        </Grid>
        <Grid container item xs={6} md={4} justifyContent="center">
          <Grid>
            <Avatar alt="png" src="Ellipse 2.png" />
          </Grid>
          <Grid paddingLeft={1}>
            {fullName && profile?.department ? (
              <>
                <Typography
                  style={{
                    color: '#00000',
                    fontWeight: 900,
                    lineHeight: '19px',
                    fontFamily: 'Lato',
                  }}
                >
                  {fullName}
                </Typography>
                <Typography style={{ color: '#2559C3' }}>
                  {profile?.department}
                </Typography>
              </>
            ) : (<Loader />)}
          </Grid>
          <Grid>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={handleClick}
            >
              <KeyboardArrowDown style={{ color: '#2559C3' }} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {/* <MenuItem component={Link} to="/profile" onClick={handleClose}>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                My profile
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
              <MenuItem onClick={hanldeChangePassowrd}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Change Password
              </MenuItem>
            </Menu>
            <ConfirmationModal
              open={showConfirmation}
              onClose={handleModalClose}
              onConfirmLogout={handleConfirmLogout}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
