import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select'; // Import SelectChangeEvent
import { IDropdown } from './types';

const Dropdown = (props: IDropdown) => {
  const {
    options,
    inputLabel,
    width = 200,
    onChange,
    value,
    onBlur,
    disabled = false,
    padding,
    isMultiSelect = false,
  } = props;
  const selectedValue = isMultiSelect ? (value || []) : value ? [value] : [];
  const [isHovered, setIsHovered] = React.useState(false);
  const selectRef = React.useRef<HTMLDivElement>(null);

  return (
    <Box>
      <FormControl variant="outlined" fullWidth>
        <label style={{ paddingLeft: padding || '15px', fontWeight: 400 }}>
          {inputLabel}
        </label>
        <Select
          ref={selectRef}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={isMultiSelect ? (selectedValue || []) : selectedValue || ''}
          onChange={onChange}
          disabled={disabled}
          multiple={isMultiSelect}
          open={isHovered}
          onClose={() => setIsHovered(false)}
          onOpen={() => setIsHovered(true)}
          onBlur={onBlur}
          renderValue={(selected) => {
            if (isMultiSelect) {
              const selectedArray = Array.isArray(selected) ? selected : [selected];
              return selectedArray.join(', ');
            } else {
              return selected as string;
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.optionValue}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

Dropdown.defaultProps = {
  options: [
    { id: 1, label: 'Twenty', optionValue: 20 },
    { id: 3, label: 'Thirty', optionValue: 30 },
  ],
  inputLabel: 'Age',
} as Partial<IDropdown>;

export default Dropdown;
