import React from 'react';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { ITableProps } from './types';

const Table = (props: ITableProps) => {
  const {
    customClasses = {},
    columns,
    data,
    sx,
    align,
    containerWidth,
    padding,
    size,
  } = props;

  return (
    <TableContainer component={Paper} sx={{ width: `${containerWidth}px` }}>
      <MUITable
        aria-label="simple table"
        stickyHeader
        sx={{
          maxWidth: `${containerWidth}px`,
          width: '100%',
          ...sx,
        }}
        classes={{ ...customClasses }}
        {...props}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={sx} align={align} padding={padding} size={size} key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row}>
              {columns.map((column) => (
                <TableCell sx={sx} align={align} padding={padding} size={size} key={column.id}>{row[column.id]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

Table.defaultProps = {
  children: '',
  columns: [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'age', label: 'Age' },
    { id: 'number', label: 'Number' },
  ],
  data: [
    {
      id: 1, name: 'John Doe', age: 25, number: 7611,
    },
    {
      id: 2, name: 'John Doe', age: 25, number: 7611,
    },
  ],
  padding: 'normal',
  stickyHeader: false,
  size: 'normal',
  align: 'center',
  containerWidth: 600,
};

export default Table;
