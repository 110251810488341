import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  TextField,
  Avatar,
  Typography,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TableColumn, TableData } from './type';
import { ListTableWrapper, AntSwitch } from './ListTable.styled';
import SearchIcon from '@mui/icons-material/Search';
import { truncateText } from '../../common/helper';
import { CheckBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface ListTableProps {
  tabValue: string;
  columns: TableColumn[];
  data: TableData[];
  tableName?: string;
  isSubmitClicked?: boolean;
  downloadCsv?: any;
  handleEdit?: () => void;
  actions?: {
    icon?: React.ReactNode;
    label: string;
    color: string;
    onClick: (item: TableData) => void;
    disabled?: boolean;
  }[];
  itemsPerPage?: number;
  onActionClick?: (action: any, item: TableData) => void;
  isStatus?: boolean;
  timeout: number;
  renderRow?: (item: TableData, index: number) => React.ReactNode;
  heading?: string;
  buttonText?: string;
}

function ListTable({
  tabValue,
  columns,
  data,
  tableName,
  isSubmitClicked,
  downloadCsv,
  handleEdit,
  actions,
  itemsPerPage = 10,
  onActionClick,
  isStatus = true,
  timeout,
  renderRow,
  heading,
  buttonText,
}: ListTableProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPage, setSearchPage] = useState(1);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const navigate = useNavigate();
  const [editKRAData, setEditKRAData] = useState({
    title: '',
    designation: [],
    responsibilites: '',
  });
  const [designation, setDesignation] = useState([]);

  const filteredData = isLoading
    ? new Array(itemsPerPage).fill(null)
    : data.filter((item) => {
        if (selectedDepartment && item.department !== selectedDepartment) {
          return false;
        }

        if (tableName === 'Employee Eligibility') {
          const lowerCaseSearchQuery = searchQuery.toLowerCase();
          return item.employees.some((employee: any) => {
            const employeeName = employee?.name?.toString().toLowerCase() || '';
            return employeeName.includes(lowerCaseSearchQuery);
          });
        } else {
          return columns.some((column) => {
            const columnAccessor = column?.accessor;
            const columnValue =
              item && columnAccessor ? item[columnAccessor] : undefined;

            if (columnValue !== undefined && columnValue !== null) {
              const lowerCaseColumnValue = columnValue.toString().toLowerCase();
              const lowerCaseSearchQuery = searchQuery.toLowerCase();
              return lowerCaseColumnValue.includes(lowerCaseSearchQuery);
            }
            return false;
          });
        }
      });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = filteredData.slice(startIndex, endIndex);
  const totalRecords = filteredData?.length;

  useEffect(() => {
    if (currentPage > totalPages && totalPages !== 0) {
      setCurrentPage(totalPages);
    }

    if (visibleData.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [filteredData, currentPage, totalPages, visibleData]);

  useEffect(() => {
    setCurrentPage(searchPage);
  }, [searchPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, currentPage]);

  const handleAddNewClick = (kraData: any) => {
    const { title, designation: desig, responsibilites, id = '' } = kraData;
    const data =
      title !== undefined
        ? { title, designation: desig, responsibilites, id }
        : { title: '', designation: [] };
    navigate('/kra/create-kra', {
      state: {
        data: data,
        designationList: designation,
      },
    });
  };

  return (
    <ListTableWrapper>
      <div className='list_table-main-table'>
        <div className='table-row-item srch_position' style={{ border: 0, marginBottom: tableName === 'Employee Eligibility' ? '15px' : '-15px' }}>
          {tableName === 'Employee Eligibility' && (
            <Typography
              variant='h6'
              className='employee-details'
              component='h6'
              fontWeight='bold'
              style={{ flexGrow: 1 }}
            >
              Employee Details
            </Typography>
          )}
          <Stack
            className='rbac_content_header'
            direction='row'
            justifyContent='space-between'
            flexGrow={1}
            alignItems='center'
            mb={2}
          >
            <Typography variant='h5'>{heading}</Typography>
          </Stack>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{ mb: 2, gap: 2 }}
          >
            <Stack>
              <TextField
                label={
                  tableName === 'Employee Eligibility'
                    ? 'Search Employee'
                    : 'Search'
                }
                sx={{ marginBottom: '15px' }}
                variant='outlined'
                size='small'
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton size='small'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{
                  // width: tableName === 'Employee Eligibility' ? '40%' : '100%',
                  width: '100%',
                  marginTop:
                    tableName === 'Employee Eligibility' ? '15px' : undefined,
                }}
              />
            </Stack>
            <Stack
              className='rbac_content_header'
              direction='row'
              justifyContent='space-between'
              flexGrow={1}
              alignItems='center'
              mb={2}
            >
              {buttonText && (
                <div
                  style={{
                    display: 'flex',
                    paddingRight: '1rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: 'max-Content',
                    }}
                    variant='contained'
                    // className='cs_btn_type'
                    size='large'
                    onClick={handleAddNewClick}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </Stack>
            {tableName === 'Employee Eligibility' && (
              <>
                <Autocomplete
                  id='filter-select'
                  options={data.map((item) => item.department)}
                  getOptionLabel={(option) => option}
                  onChange={(_event, newValue) =>
                    setSelectedDepartment(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Filter'
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  )}
                />
                <Button
                  variant='contained'
                  disabled={!isSubmitClicked}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  variant='contained'
                  startIcon={<DownloadIcon />}
                  disabled={!isSubmitClicked}
                  onClick={downloadCsv}
                >
                  Download
                </Button>
              </>
            )}
          </Box>
        </div>
        <TabContext value={tabValue}>
          <TabPanel value={tabValue} className='override-tabpanel'>
            <div className='table-row-item-cell table-header-modify'>
              {columns.map((column) => (
                <Box key={column?.accessor} className='list_table-table-head'>
                  {column.header}
                </Box>
              ))}
              {isStatus && (
                <Box className='list_table-table-head list-table-status-head'>
                  Status
                </Box>
              )}
              {Array.isArray(actions) && (
                <Box className='list_table-table-head list-table-action-head'>
                  Actions
                </Box>
              )}
            </div>
            {isLoading ? (
              Array.from({ length: itemsPerPage / 2 }).map((_, index) => (
                <div className='table-row-item' key={index}>
                  {columns.map((column) => (
                    <Box
                      className='list-list_table-box-wrap'
                      key={column?.accessor}
                    >
                      <List>
                        <ListItem className='list-list_table'>
                          <Skeleton
                            width={300}
                            animation='wave'
                            variant='text'
                          />
                        </ListItem>
                      </List>
                    </Box>
                  ))}
                  {isStatus && (
                    <Box className='list-list_table-box-wrap' key={'status'}>
                      <List>
                        <ListItem className='list-list_table'>
                          <Skeleton
                            width={150}
                            animation='wave'
                            variant='text'
                          />
                        </ListItem>
                      </List>
                    </Box>
                  )}
                  {Array.isArray(actions) && (
                    <Box className='list-list_table-box-wrap' key={'actions'}>
                      <List>
                        <ListItem className='list-list_table'>
                          <Skeleton
                            width={150}
                            animation='wave'
                            variant='text'
                          />
                        </ListItem>
                      </List>
                    </Box>
                  )}
                </div>
              ))
            ) : visibleData && visibleData?.length === 0 ? (
              <p style={{ textAlign: 'center', fontWeight: 400 }}>
                No data to display
              </p>
            ) : (
              visibleData?.map((item, index) => {
                return renderRow ? (
                  renderRow(item, index)
                ) : (
                  <div className='table-row-item' key={index}>
                    {columns?.map((column) => {
                      const elem =
                        item?.[column?.accessor] !== undefined
                          ? truncateText(item?.[column?.accessor], 30)
                          : '';
                      const desgArray = item['designation'];
                      const data = desgArray
                        ?.map((desg: { name: any }) => desg?.name)
                        .join(', ');

                      return (
                        <Box
                          className='list-list_table-box-wrap'
                          key={column?.accessor}
                        >
                          <List>
                            <ListItem className='list-list_table'>
                              {column.type === 'image' ||
                              column.type === 'Image' ? (
                                <Avatar
                                  alt='Image'
                                  src={decodeURIComponent(
                                    item?.[column?.accessor]
                                  )}
                                />
                              ) : column?.type === 'html' ||
                                column?.type === 'HTML' ? (
                                // Render HTML content
                                <div>
                                  {Array.isArray(elem) ? (
                                    elem.map((item, index) => (
                                      <div key={index}>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item,
                                          }}
                                        />
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{ __html: elem }}
                                    />
                                  )}
                                </div>
                              ) : column?.accessor === 'designation' ? (
                                <Tooltip title={data} arrow>
                                  <ListItemText
                                    className='listIem-text'
                                    primary={truncateText(data, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === 'name' ? (
                                <Tooltip title={item?.name} arrow>
                                  <ListItemText
                                    className='listIem-text'
                                    primary={truncateText(item?.name, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === 'buHead' ? (
                                <Tooltip title={item?.buHead} arrow>
                                  <ListItemText
                                    className='listIem-text'
                                    primary={truncateText(item?.buHead, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === 'hrBPs' ? (
                                <Tooltip title={item?.hrBPs} arrow>
                                  <ListItemText
                                    className='listIem-text'
                                    primary={truncateText(item?.hrBPs, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === 'responsibilites' ? (
                                <Tooltip
                                  title={
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item?.responsibilites,
                                      }}
                                    />
                                  }
                                  arrow
                                >
                                  <ListItemText
                                    className='listIem-text'
                                    primary={
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: String(
                                            truncateText(
                                              item?.responsibilites,
                                              20
                                            )
                                          ),
                                        }}
                                      />
                                    }
                                  />
                                </Tooltip>
                              ) : column?.accessor ===
                                'AppraisalEligibility' ? (
                                <CheckBox />
                              ) : column?.accessor ===
                                'PromotionEligibility' ? (
                                <CheckBox />
                              ) : (
                                // Render plain text
                                <ListItemText
                                  className='listIem-text'
                                  primary={
                                    item?.[column?.accessor] !== undefined
                                      ? truncateText(
                                          item?.[column?.accessor],
                                          20
                                        )
                                      : ''
                                  }
                                />
                              )}
                            </ListItem>
                          </List>
                        </Box>
                      );
                    })}
                    {isStatus && (
                      <Box className='status-field-wrap' m={2}>
                        <Stack
                          direction='row'
                          spacing={1}
                          alignItems='center'
                          style={{ marginLeft: '15px' }}
                        >
                          {/* <Typography>Inactive</Typography> */}
                          <AntSwitch
                            checked={item['status'] === 1}
                            inputProps={{ 'aria-label': 'ant design' }}
                            onClick={() => {
                              if (onActionClick) {
                                onActionClick({ label: 'status' }, item);
                              }
                            }}
                          />
                          {/* <Typography >Active</Typography> */}
                        </Stack>
                      </Box>
                    )}
                    <Box className='actions-field-wrap'>
                      {actions?.map((action, actionIndex) => {
                        const combinedAction = {
                          ...action,
                          disabled: action.disabled && !isSubmitClicked,
                        };
                        return (
                          <span key={actionIndex}>
                            <Tooltip title={combinedAction.label} arrow>
                              <IconButton
                                // color={combinedAction.color}
                                key={index}
                                size='large'
                                className={
                                  combinedAction.color
                                    ? combinedAction.color
                                    : 'primary-icon'
                                }
                                onClick={() => {
                                  if (onActionClick) {
                                    onActionClick(combinedAction, item);
                                  }
                                }}
                                disabled={combinedAction.disabled}
                              >
                                {combinedAction.icon}
                              </IconButton>
                            </Tooltip>
                          </span>
                        );
                      })}
                    </Box>
                  </div>
                );
                // }
              })
            )}
          </TabPanel>
        </TabContext>
      </div>
      <Box mt={2} display='flex' justifyContent='flex-end'>
        <div className='pgntn_box'>
          <Typography
            className='showing_rslt'
            variant='body2'
            color='textSecondary'
          >
            Showing {visibleData && visibleData?.length} records out of{' '}
            {totalRecords} total.
          </Typography>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color='primary'
            size='large'
            className='pagination cs_pgntn'
            siblingCount={1}
            boundaryCount={1}
            showFirstButton
            showLastButton
          />
        </div>
      </Box>
    </ListTableWrapper>
  );
}

export default ListTable;
