import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { IDialogProps } from './types';

const DialogBox = (props: IDialogProps) => {
  const {
    id, open,
    title, onDialogConfirm, confirmText,
    cancelText, onDialogCancel, dialogContent,
  } = props;

  const handleSubmit = () => {
    onDialogConfirm();
  };
  const onCancel = () => {
    onDialogCancel();
  };
  return (
    <Dialog
      key={id}
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle style={{ cursor: 'move' }}>
        { title }
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          {cancelText}
        </Button>
        <Button onClick={handleSubmit}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
