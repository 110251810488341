import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
    flex: 0 0 100%;
    text-align: center;
`;

const InputTextField = styled(TextField)`
    margin-bottom: 8px !important;
`;

const StyledButton = styled(Button)`
    width: 100%;
`;

export { Container, InputTextField, StyledButton };
