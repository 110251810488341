import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { Box } from '@mui/system';

const Container = styled.div`
    flex: 0 0 100%;
    text-align: center;
`;
const InputTextField = styled(TextField)`
    margin-bottom: 8px !important;
`;
const StyledButton = styled(Button)`
    width: 100%;
`;

const StyledBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '4px',
  boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  padding: '15px',
  maxHeight: 'calc(100% - 64px)',
  maxWidth: '444px',
  width: 'calc(100% - 64px)',
});

export {
  Container, InputTextField, StyledBox, StyledButton,
};

