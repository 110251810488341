import { LogLevel } from './types';
import config from '../../config/config';

const { debug } = config;

/** The App environment */
export type Debug = 'true' | 'false';

export const APP_DEBUG: Debug = debug === 'true' ? 'true' : 'false';

export const LOG_LEVEL: LogLevel = APP_DEBUG === 'true' ? 'log' : 'warn';
