import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { AxiosResponse } from 'axios';

import {
  Container,
  InputTextField,
  StyledButton,
} from './AddTaskForm.styles';
import { CommHandler } from '../../utils';
import { apiRequests } from '../../config';
import { IFormProps } from './types';
import { ITaskProps } from '../../modules/TodoList/types';
import { logger } from '../../libs/index';

const AddTaskForm = (props: IFormProps) => {
  const {
    setTasks,
    formTitle,
    addTaskButton,
  } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!title.trim() || !description.trim()) {
      return;
    }
    let addToDoTask: AxiosResponse;
    try {
      addToDoTask = await CommHandler.request({ ...apiRequests.addToDoOptions, data: { title, description } });
    } catch (error) {
      logger.error(error);
    }
    setTitle('');
    setDescription('');
    setTasks((prevTasks: ITaskProps[]) => [...prevTasks, addToDoTask.data]);
  };

  const handleTitleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setDescription(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <h1>{formTitle}</h1>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <InputTextField
            label="Task title"
            variant="filled"
            color="success"
            value={title}
            onChange={handleTitleChange}
            focused
          />
          <InputTextField
            label="Description"
            variant="filled"
            color="success"
            value={description}
            onChange={handleDescriptionChange}
            focused
          />
        </Box>
      </Container>
      <StyledButton
        type="submit"
        variant="outlined"
      >
        {addTaskButton}
      </StyledButton>
    </form>
  );
};

export default AddTaskForm;
