import React,
{
  Component,
  ErrorInfo,
  ReactNode,
} from 'react';

import errorLabels from '../../cms';
import { logger } from '../../libs';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    logger.error('Uncaught error:', _);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    if (hasError) {
      const { errorStatement } = errorLabels;
      return <h1>{errorStatement}</h1>;
    }
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
