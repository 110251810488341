import React from 'react';
import { IImage } from './types';

const Image = (props:IImage) => {
  const {
    alt,
    src,
    width,
    height,
  } = props;

  const imageStyle = {
    width,
    height,
    cursor: 'pointer',
  };

  return (
    <img
      style={imageStyle}
      src={src}
      alt={alt}
      loading="lazy"
    />
  );
};

Image.defaultProps = {
  alt: '',
  src: '',
};
export default Image;
