import React, { ReactNode } from "react";
import { Grid, backdropClasses } from "@mui/material";
import Header from "./header";
import SideBar from "./sidebar";
import { Container } from "@mui/system";
// import { StyledHomePageWrapper } from '../modules/Dashboard/Dashboard.styled';

interface PrivateAppLayoutProps {
  children: ReactNode;
}

const PrivateAppLayout = ({ children }: PrivateAppLayoutProps) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      height: '100vh',
      // width: '90vw',
    }}
  >
    <Grid item lg={12} xs={12} md={12} sm={12}>
      <Header />
    </Grid>
    <Grid item lg={1} md={1} sm={1} xs={1}>
      <SideBar />
    </Grid>
    <Grid
      item
      lg={11}
      md={11}
      sm={11}
      xs={11}
      sx={{
        // position: 'fixed',
        // top: '95px',
        // left: '0',
        background: '#f7f7f7',
        minHeight: 'calc(100vh - 77px)',
        // height: 'auto',
        // width: '90%',
        marginLeft: '90px', 
        marginTop:'70px',
        flex:'1 1 calc(100% - 100px)',
        width:'calc(100% - 100px)',
        maxWidth:'100% !important',
        flexBasis:'calc(100% - 100px) !important',
        padding:'2rem 3.5rem',
        backgroundColor: '#EFF1F8'
      }}
    >
      {children}
    </Grid>
  </Grid>
);

export default PrivateAppLayout;
