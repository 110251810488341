import { LogLevel, ILogger, ILogFn } from './types';
import { LOG_LEVEL } from './loggerHandler';

const NO_OP = () => {};

export class ConsoleLogger implements ILogger {
  readonly log: ILogFn;

  readonly warn: ILogFn;

  readonly error: ILogFn;

  constructor(options?: { level? : LogLevel }) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}

const logger = new ConsoleLogger({ level: LOG_LEVEL });
export default logger;

