import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth:300,
      width:300,
    },
  },
};

const MultiSelectDropdown = (props: any) => {
  const { inputLabel, onChange, value, options } = props;
  const renderVal = value.length && !Array.isArray(value) ? value.split(', ') : value ;

  return (
    <>
      <Box>
        <FormControl fullWidth>
          <label>
            {inputLabel}
          </label>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={renderVal}
            onChange={onChange}
            MenuProps={MenuProps}
          >
            {options.map((item: any) => (
              <MenuItem key={item.id} value={item.label}>
                <Checkbox checked={value.indexOf(item.label) > -1} />{item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

MultiSelectDropdown.defaultProps = {
  options: [
    { id: 1, label: "Twenty", optionValue: 20 },
    { id: 3, label: "Thirty", optionValue: 30 },
  ],
  inputLabel: "Age",
} as Partial<any>;

export default MultiSelectDropdown;
