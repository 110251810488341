import styled from 'styled-components';

const StyledNotFoundContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    height: 50vh;
`;
const StyledErrorCode = styled.div`
    color: red; 
    font-size: 30px;
`;

export {
  StyledNotFoundContainer,
  StyledErrorCode,
};
